label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.navbar {
  color: white;
  background-color: #485566;
  --bs-navbar-brand-color: white;
  --bs-navbar-brand-hover-color: white;
}

.logo {
  font-size: 20px;
}

.navbar-nav {
  --bs-nav-link-hover-color: white;
  --bs-nav-link-color: white;
}

.nav-link {
  --bs-navbar-nav-link-padding-x: 0.9rem;
  --bs-navbar-brand-color: white;
  --bs-navbar-brand-hover-color: white;
  --bs-navbar-active-color:white;
  border: solid 3px #485566;
}

.nav-link.active {
  border: solid 3px white;
}

.button:hover {
  background-color: #538cba;
  color: white;
}

#search-result {
  min-height: 200px;
}

#license-output {
  height: 400px;
}

.validation-message {
  display: block;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #538cba;
  --bs-btn-border-color: #538cba;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #538cba;
  --bs-btn-hover-border-color: #538cba;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #538cba;
  --bs-btn-active-border-color: #538cba;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #538cba;
  --bs-btn-disabled-border-color: #538cba;
}

.settings-row {
  margin-bottom: 1em;
}

.spinner-border {
  margin-right: 10px;
}